window.initFooterMap = function()
{
    const map = new google.maps.Map(document.getElementById('footer-map'), {
        zoom: 5,
        center: {lat: 47.9117796, lng: 1.8994233},
        zoomControl: true,
        fullscreenControl: true,
        mapTypeControl: false,
        streetViewControl: false,
        rotateControl: false,
    });

    const icon = {
        path: 'M31,0 C14.302,0 0.719,13.584 0.719,30.281 C0.719,39.533 5.986,47.242 11.562,55.404 C18.75,65.926 26.897,77.851 26.897,96.6 C26.897,98.866 29.637,100 31,100 C32.363,100 35.103,98.866 35.103,96.6 C35.103,77.851 43.25,65.926 50.438,55.405 C56.015,47.243 61.281,39.534 61.281,30.282 C61.281,13.584 47.698,0 31,0 Z M31,38.281 C26.5888963,38.281 23,34.692432 23,30.281 C23,25.869568 26.5888963,22.281 31,22.281 C35.4111037,22.281 39,25.869568 39,30.281 C39,34.692432 35.4111037,38.281 31,38.281 Z',
        fillColor: '#3b62ac',
        fillOpacity: 1,
        anchor: new google.maps.Point(31, 100),
        strokeWeight: 0,
        scale: 0.35
    };

    const markers = [
        {lat: 47.9117796, lng: 1.8994233},
        {lat: 48.4431337, lng: 1.4863129},
        {lat: 48.0642748, lng: 1.3226923},
        {lat: 43.6133483, lng: 1.3061045},
        {lat: 43.6182861, lng: 3.9106786},
        {lat: 47.3661564, lng: 0.6782257},
        {lat: 47.3956432, lng: 0.7823608},
        {lat: 47.8939676, lng: 1.8919796},
        {lat: 47.8427278, lng: 1.9256021},
        {lat: 47.8470847, lng: 1.9213123},
        {lat: 47.8752456, lng: 1.7639191},
        {lat: 47.9283553, lng: 2.1638652},
        {lat: 47.9099096, lng: 2.1350699},
        {lat: 47.9773523, lng: 2.1043725},
        {lat: 47.4896088, lng: -0.5099594},
        {lat: 47.749935, lng: -3.3619764},
        {lat: 45.7815447, lng: 4.8055338},
        {lat: 48.8628046, lng: 2.3486408},
        {lat: 48.8739553, lng: 2.3076644},
        {lat: 48.8729217, lng: 2.3322014},
        {lat: 48.8642829, lng: 2.3785185},
        {lat: 49.4424717, lng: 1.0816934},
        {lat: 48.8363323, lng: 2.0725527},
        {lat: 46.670398, lng: -1.4043114},
        {lat: 45.8200699, lng: 1.2359319},
        {lat: 48.6523959, lng: 2.2743659},
        {lat: 48.8344489, lng: 2.2356716},
        {lat: 48.8297443, lng: 2.2639483},
        {lat: 48.9561542, lng: 2.3390739},
        {lat: 50.633286, lng: 3.020352},
        {lat: 47.274098, lng: -1.620209},
        {lat: 47.923861, lng: 1.901424},
        {lat: 47.390151, lng: 0.687022},
        {lat: 48.200391, lng: 1.882834},
        {lat: 47.888899, lng: 1.834657},
        {lat: 48.885474, lng: 2.291613},
        {lat: 43.697425, lng: 7.270309},
        {lat: 47.898104, lng: 2.034176},
        {lat: 48.445223, lng: 1.486919},
        {lat: 48.58022302091916, lng: 7.748359788619312},
        {lat: 48.56582282438629, lng: 7.752454670547738},
        {lat: 47.31673282814358, lng: 5.037369513072124},
        {lat: 47.31858352404247, lng: 5.072386547161887},
        {lat: 47.102729011707346, lng: 5.484286880020827},
        {lat: 47.84769015158824, lng: 1.9226891757324158},
        {lat: 47.91646233028827, lng: 1.9004993936582424},
        {lat: 47.95066113564874, lng: 1.899212805062488},
        {lat: 47.909188350049774, lng: 1.8914925725945975},
        {lat: 47.82561035747844, lng: 1.9256586786339378},
        {lat: 43.5626530225727, lng: 1.441601309927997},
        {lat: 47.36517640319555, lng: 0.7041879630182222},
    ];

    markers.forEach(function(coords, i) {
        i === 0
            ? new google.maps.Marker({map: map, position: coords, icon: {...icon, fillColor: '#ef7d3a'}, zIndex: 99})
            : new google.maps.Marker({map: map, position: coords, icon: icon})
    });
};