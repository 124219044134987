const $mobileHeaderToggle = $('.mobile-header-toggle');
const $header = $('.header');
const $scrollDownToggle = $('.scroll-down-toggle');
const $scrollTargetToggle = $('[data-scroll-target]');
const $phoneCtaToggle = $('.phone-cta-toggle');
const $phoneCta = $('.phone-cta');
const $teamSlider = $('.team-slider');
const $cookieBanner = $('.cookie-banner');

$(window).resize(function() {
    $header.removeClass('mobile-menu');
});

$mobileHeaderToggle.on('click', function() {
    $header.toggleClass('mobile-menu');
});

$phoneCtaToggle.on('click', function() {
    $phoneCta.toggleClass('active');
});

$scrollDownToggle.each(function() {
    $(this).on('click', function() {
        let $closest = $(this).closest('.fullpage-section').next('.fullpage-section');
        if (!$closest.length) $closest =  $('.fullpage-section.footer');
        $('html, body').animate({
            scrollTop: $closest.offset().top - $header.outerHeight(true)
        }, 500);
    })
});

$scrollTargetToggle.each(function() {
    $(this).on('click', function() {
        const $target = $($(this).data('scroll-target'));
        $('html, body').animate({
            scrollTop: $target.offset().top - $header.outerHeight(true)
        }, 500);
    })
});

if ($teamSlider.length > 0) {
    $teamSlider.slick({
        dots: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: 0,
        infinite: true,
        responsive: [
            {breakpoint: 992, settings: {slidesToShow: 1}},
        ]
    });
}

$cookieBanner.find('.btn-accept, .btn-close').on('click', function() {
    $cookieBanner.hide();
    localStorage.setItem('accept_cookies', '1');
});
if (!localStorage.getItem('accept_cookies')) $cookieBanner.show();

/** Hide or show landing cta */
$landingCta = $('.landing-cta');
if ($landingCta.length > 0) {
    $topSection = $('.fullpage-section:first-child');
    $landingForm = $('#landing-form');

    $(document).on('scroll', function () {
        const scrollPosition = $(document).scrollTop() + $(window).height();
        const minScroll = $topSection.offset().top + $topSection.height() + 100;
        const maxScroll = $landingForm.offset().top + 100;

        if (scrollPosition > minScroll && scrollPosition < maxScroll) {
            $landingCta.css('display', 'block');
        } else {
            $landingCta.css('display', 'none');
        }
    });
}

// Hide hubspot form advertisement
$('.hideHubspotAd').on('click', function() {
    setTimeout(function() {
        $hubspotForm = $('.hs-form-iframe');
        if ($hubspotForm.length > 0) {
            $hubspotForm.height($hubspotForm.height() - 90);
        }
    }, 120);
});
